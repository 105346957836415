import styled from 'styled-components'
import {palette} from 'styled-tools'

import {fluidRange} from '@festi/common/utils/styles'
import {VariantDetail, VariantList} from '@festi/common/api/rest'

interface LabelProps {
  bannerColor: string
  fontColor: string
}

export const GeneralLabel = styled.div<LabelProps>`
  position: absolute;
  top: -8px;
  left: 16px;
  ${fluidRange('left', '16px', '32px')}
  color: ${palette('blue')};
  font-family: 'ElkjopHeadline', sans-serif;
  ${fluidRange('font-size', `${0.875 * 16}px`, `${1.25 * 16}px`)}
  z-index: 2;

  &:before {
    content: '';
    position: absolute;
    top: -6px;
    bottom: -6px;
    left: -8px;
    right: -8px;
    background-color: ${palette('green')};
    transform: rotate(-5deg);
    z-index: -1;
  }
`

const BannerLabel = styled(GeneralLabel)`
  &:before {
    background-color: ${(props) => props.bannerColor};
  }
  // this is font color
  color: ${(props) => props.fontColor};
`
export const itemBannerColorMap = {
  Forsala: [palette('blue'), palette('white')],
  Afmælistilboð: [palette('lightBlue'), palette('blue')],
  'Bleikur október': [palette('pink'), palette('black')],
  'Svartur fössari': [palette('black'), palette('white')],
}

interface Props {
  variant: VariantDetail | VariantList
}

export default function ProductImageBanner({variant}: Props) {
  const [bannerColor, bannerFontColor] =
    itemBannerColorMap[variant.banner] ?? []

  return (
    <>
      {bannerColor ? (
        <BannerLabel bannerColor={bannerColor} fontColor={bannerFontColor}>
          {variant.banner}
        </BannerLabel>
      ) : (
        !!variant?.recommended && (
          <BannerLabel
            bannerColor={palette('green') as unknown as string}
            fontColor={palette('blue') as unknown as string}
          >
            ELKO MÆLIR MEÐ
          </BannerLabel>
        )
      )}
    </>
  )
}
