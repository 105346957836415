import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import * as Select from '@radix-ui/react-select'
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon'
import {styledTheme} from '@festi/common/themes'

const SelectRoot = styled(Select.Root)`
  position: relative;
  width: 100%;
`

const Label = styled.label`
  display: none;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  color: ${styledTheme.palette.ui60Solid};
  transform-origin: left top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 32px);
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(16px, 6px);
  pointer-events: none;

  &.large {
    transform: translate(16px, 9px);
  }
`

const SelectIcon = styled(Select.Icon)`
  position: absolute;
  top: 16px;
  right: 16px;

  &.large {
    top: 20px;
  }
`

const SelectTrigger = styled(Select.Trigger)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  height: 48px;
  background-color: ${styledTheme.palette.white};
  border: 1px solid ${styledTheme.palette.grey};
  border-radius: 8px;
  padding-inline: 16px;
  padding-block: 13px;
  color: ${styledTheme.palette.blue};
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  cursor: ${styledTheme.cursor.pointer};

  &.large {
    height: 56px;
    padding-block: 17px;
  }
  &:focus-visible,
  &:hover {
    border-color: ${styledTheme.palette.ui30Solid};
  }
  &:focus {
    outline: none;
  }
  &[data-placeholder] {
    color: ${styledTheme.palette.ui60Solid};
    font-weight: 400;
  }
  &[data-state='open'] {
    border-color: ${styledTheme.palette.ui30Solid};
  }
  &[data-state='open'] ${SelectIcon} {
    transform: rotate(180deg);
  }
  &:not([data-placeholder]) {
    padding-bottom: 2px;
  }
  &:not([data-placeholder]) ${Label} {
    display: block;
  }
`

const SelectViewport = styled(Select.Viewport)`
  background-color: ${styledTheme.palette.white};
  border-radius: 8px;
  box-shadow: ${styledTheme.boxShadow.simpleSubtle};
`

const StyledItem = styled(Select.Item)`
  background-color: ${styledTheme.palette.white};
  border-bottom: 1px solid ${styledTheme.palette.ui10Solid};
  height: 46px;
  width: var(--radix-select-trigger-width);
  padding-inline: 16px;
  padding-block: 12px;
  color: ${styledTheme.palette.blue};
  font-size: 16px;
  font-weight: 400;
  transition: background-color 10ms ease-in dissolve;
  user-select: none;
  cursor: ${styledTheme.cursor.pointer};

  &[data-highlighted] {
    outline: none;
    background-color: ${styledTheme.palette.light};
  }
  &[data-state='checked'] {
    background-color: ${styledTheme.palette.primaryLightestBlue};
  }
`

interface DropdownSelectProps extends Select.SelectProps {
  items: {label: string; value: string}[]
  label: string
  large?: boolean
}

export default function DropdownSelect({
  label,
  items,
  large,
  ...props
}: DropdownSelectProps) {
  return (
    <SelectRoot {...props}>
      <SelectTrigger aria-label={label} className={classNames({large})}>
        <Label className={classNames({large})}>{label}</Label>
        <Select.Value placeholder={label} />
        <SelectIcon className={classNames({large})} asChild>
          <ArrowDownSLineIcon size={16} color={styledTheme.palette.ui30Solid} />
        </SelectIcon>
      </SelectTrigger>
      <Select.Portal>
        <Select.Content position="popper" sideOffset={2}>
          <SelectViewport>
            {items.map((item, i) => (
              <SelectItem key={i} value={item.value}>
                {item.label}
              </SelectItem>
            ))}
          </SelectViewport>
        </Select.Content>
      </Select.Portal>
    </SelectRoot>
  )
}

const SelectItem = React.forwardRef<HTMLDivElement, Select.SelectItemProps>(
  ({children, ...props}, forwardedRef) => {
    return (
      <StyledItem {...props} ref={forwardedRef}>
        <Select.ItemText>{children}</Select.ItemText>
      </StyledItem>
    )
  },
)

SelectItem.displayName = 'SelectItem'
