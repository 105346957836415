import React from 'react'
import {useState} from 'react'

import cn from 'classnames'
import {
  isBefore,
  isSameDay,
  sub as subDate,
  format as formatDate,
  parse as parseDate,
  parseISO as parseISODate,
  eachDayOfInterval,
  endOfDay,
} from 'date-fns'
import sortBy from 'lodash/sortBy'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import PriceTag3LineIcon from 'remixicon-react/PriceTag3LineIcon'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette, theme} from 'styled-tools'

import {VariantPriceHistory} from '@festi/common/api/rest'
import {
  Modal,
  ModalContentWide,
  ModalSize,
} from '@festi/common/components/common'
import {H4, PSmall} from '@festi/common/components/typography'
import {styledTheme} from '@festi/common/themes'
import {formatPrice} from '@festi/utils/numbers'

interface Props {
  isOpen: boolean
  onClose?: () => void
  sku: string
  name?: string
  priceHistory: VariantPriceHistory[]
}

interface InnerProps {
  priceHistory: VariantPriceHistory[]
}

interface DataPoint {
  date: Date
  dateLabel: string
  price: string
  discountedPrice: number
}

interface ParsedPriceHistory extends Omit<VariantPriceHistory, 'date'> {
  date: Date
}

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .remixicon-icon {
    margin-right: 16px;
  }
`

const ChartContainer = styled.div`
  padding: 16px 0 0 0;
  margin-bottom: 32px;
  width: 100%;
  height: 200px;

  .recharts-cartesian-axis-tick-value {
    font-family: 'ElkjopSans', sans-serif;
    font-size: 1rem;
    fill: ${palette('blue')};
  }

  .recharts-tooltip-cursor {
    stroke: ${palette('ui40Solid')} !important;
    opacity: 1 !important;
  }

  .tooltip-wrapper {
    border: 0 !important;
    border-top: 4px solid ${palette('blue')} !important;
    background-color: ${palette('white')} !important;
    box-shadow: ${theme('boxShadow.card')} !important;
    padding: 12px !important;
  }

  .tooltip-label {
    font-family: 'ElkjopSans', sans-serif !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    color: ${palette('blue')} !important;
    margin-bottom: 5px !important;
  }

  ${media.md`
    height: 400px;
  `}

  ${media.lg`
    height: 500px;
  `}
`

const ChartHeadContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  margin-bottom: 16px;

  ${media.md`
    padding-left: 100px;
    margin-bottom: 32px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}
`

const Legends = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  ${media.md`
    width: auto;
  `}
`

const Legend = styled.div`
  font-size: 1rem;
  font-weight: 500;
  color: ${palette('blue')};
  display: flex;
  align-items: center;
  margin-left: 16px;

  ${media.md`
    margin-left: 24px;
  `}

  ${media.lg`
    margin-left: 32px;
  `}
`

const LegendLine = styled.div`
  height: 3px;
  width: 32px;
  background-color: ${palette('lightBlue')};
  margin-left: 8px;

  &.discount {
    background-color: ${palette('pink')};
  }

  ${media.md`
    width: 56px;
  `}

  ${media.lg`
    width: 80px;
  `}
`

const TimeTabs = styled.div`
  display: flex;
`

const TimeTab = styled.button`
  position: relative;
  border: 0;
  background: none;
  border-radius: 0;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  color: ${palette('blue')};
  padding: 12px;

  &:before {
    content: '';
    position: absolute;
    height: 4px;
    background-color: transparent;
    bottom: 0;
    left: 8px;
    right: 8px;
    transition: background-color 0.15s;
  }

  &:hover {
    &:before {
      background-color: ${palette('lightBlue60')};
    }
  }

  &.active {
    &:before {
      background-color: ${palette('lightBlue')};
    }
  }
`

const Bold = styled.span`
  font-weight: 600;
`

const Light = styled.span`
  color: ${palette('ui50Solid')};
  font-weight: 400;
  font-size: 1rem;
`

const months = [
  'jan',
  'feb',
  'mar',
  'apr',
  'maí',
  'jún',
  'júl',
  'ágú',
  'sep',
  'okt',
  'nóv',
  'des',
]

const tooltipLabels: Record<string, string> = {
  price: 'Verð',
  discountedPrice: 'Tilboðsverð',
}

function parseTick(tick: string) {
  const parsedDate = parseDate(tick, 'dd/MM/yyyy', new Date())
  const month = months[parseInt(formatDate(parsedDate, 'M'), 10) - 1]
  const year = formatDate(parsedDate, 'yyyy')
  return `${month} ${year}`
}

function parsePriceHistory(item: VariantPriceHistory): ParsedPriceHistory {
  return {
    ...item,
    date: parseISODate(item.date),
  }
}

export function generatePriceDataPoints(
  priceHistory: ParsedPriceHistory[],
  today: Date,
) {
  const data: DataPoint[] = []

  priceHistory.forEach((d, index) => {
    const prev = priceHistory[index - 1]
    const next = priceHistory[index + 1]
    if (prev?.date === d.date) {
      return true
    }

    const range = eachDayOfInterval({
      start: d.date,
      end: next?.date ?? endOfDay(today),
    })

    range.forEach((date) => {
      data.push({
        date,
        dateLabel: formatDate(date, 'dd/MM/yyyy'),
        price: d.price.toString(),
        discountedPrice: d.discountedPrice,
      })
    })
  })

  return data
}

function PriceHistoryModalInner({priceHistory}: InnerProps) {
  const data = sortBy(priceHistory.map(parsePriceHistory), ['date'])

  const today = new Date()
  const firstDay = data?.[0]?.date

  const filterDates: [string, Date][] = [
    ['Allt', firstDay],
    ['12 mán', subDate(today, {years: 1})],
    ['6 mán', subDate(today, {months: 6})],
    ['3 mán', subDate(today, {months: 3})],
    ['1 mán', subDate(today, {months: 1})],
  ]

  const [activeFilterDate, setActiveFilterDate] = useState<Date>(firstDay)

  // Show only data that is within the active filter date
  const formattedData: DataPoint[] = generatePriceDataPoints(
    data,
    today,
  ).filter((p) => !isBefore(p.date, activeFilterDate))

  // Get the months that are shown in the chart
  const months = formattedData.flatMap((d, index) =>
    index === 0 || d.dateLabel.indexOf('01') === 0 ? [d.dateLabel] : [],
  )

  // Show first and last element from months and show 7 elements with the same distance between them
  // if there are less than 8 dates, show all of them

  const ticks = months.filter((m, index) => {
    if (months.length <= 8) {
      return true
    }

    if (index === 0 || index === months.length - 1) {
      return true
    }

    const distance = Math.floor(months.length / 7)
    return index % distance === 0
  })

  const maxPrice = Math.max(...formattedData.map((d) => parseInt(d.price)))

  let multiplier = 1000
  if (maxPrice <= 2000) {
    multiplier = 100
  }

  return (
    <>
      <ChartHeadContainer>
        <TimeTabs>
          {filterDates.map(([label, date]) => (
            <TimeTab
              key={label}
              onClick={() => setActiveFilterDate(date)}
              className={cn({active: isSameDay(activeFilterDate, date)})}
            >
              {label}
            </TimeTab>
          ))}
        </TimeTabs>

        <Legends>
          <Legend>
            Almennt verð
            <LegendLine />
          </Legend>

          <Legend>
            Tilboðsverð
            <LegendLine className="discount" />
          </Legend>
        </Legends>
      </ChartHeadContainer>

      <ChartContainer>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={formattedData}>
            <CartesianGrid
              horizontal={false}
              stroke={styledTheme.palette.border}
            />

            <XAxis
              dataKey="dateLabel"
              tickLine={false}
              tickMargin={12}
              tick={{fontWeight: 500}}
              tickFormatter={(val) => parseTick(val)}
              ticks={ticks}
              minTickGap={16}
              axisLine={false}
            />
            <YAxis
              tickSize={0}
              tickMargin={12}
              tick={{fontWeight: 400}}
              tickFormatter={(val) => formatPrice(val)}
              width={100}
              domain={[
                0,
                (dataMax: number) =>
                  Math.ceil((dataMax * 1.25) / multiplier) * multiplier,
              ]}
              axisLine={false}
            />

            <Tooltip
              wrapperClassName="tooltip-wrapper"
              labelClassName="tooltip-label"
              formatter={(value: string, name: string) => {
                return [formatPrice(value), tooltipLabels[name]]
              }}
            />

            <Line
              type="stepAfter"
              dataKey="discountedPrice"
              stroke={styledTheme.palette.pink}
              strokeWidth={3}
              dot={false}
              activeDot={{r: 5, strokeWidth: 0}}
            />

            <Line
              type="stepAfter"
              dataKey="price"
              stroke={styledTheme.palette.lightBlue}
              strokeWidth={3}
              dot={false}
              activeDot={{r: 5, strokeWidth: 0}}
            />
          </LineChart>
        </ResponsiveContainer>
      </ChartContainer>

      <PSmall
        style={{color: styledTheme.palette.ui50Solid, textAlign: 'center'}}
      >
        Verð miðast við stöðuna í lok hvers dags. Innkaupsverð, gengi, opinber
        gjöld og fjölmörg önnur atriði hafa áhrif á verðlagningu.
      </PSmall>
    </>
  )
}

export default function PriceHistoryModal({
  isOpen,
  onClose,
  name,
  sku,
  priceHistory,
}: Props): JSX.Element {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      bottomMobile
      size={ModalSize.Medium}
    >
      <ModalContentWide>
        <TitleContainer>
          <PriceTag3LineIcon size={32} color={styledTheme.palette.lightBlue} />
          <H4>
            <Bold>Verðsaga: </Bold>
            {name}
            <Light> - {sku}</Light>
          </H4>
        </TitleContainer>

        <PriceHistoryModalInner priceHistory={priceHistory} />
      </ModalContentWide>
    </Modal>
  )
}
