import React, {useMemo} from 'react'

import Head from 'next/head'

import {removeInches} from '@festi/utils/strings'
import {getListingPrice} from '@festi/common/utils/price'
import {VariantDetail} from '@festi/common/api/rest'

interface Props {
  variant: VariantDetail
}

export default function SearchConsole({variant}: Props): JSX.Element {
  const addProductJsonLd = useMemo(() => {
    return {
      __html: `{
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": "${removeInches(variant?.product?.name)}",
        "description": "${removeInches(variant?.product?.shortDescription)}",
        "image": [
            ${variant?.images?.map((img) => `"${img.image.productList}"`)}
          ],
        "sku": "${variant?.sku}",
        "mpn": "${variant?.product?.attributes?.navBarcodeNo}",
        "brand": {
          "@type": "Brand",
          "name": "${variant?.product?.attributes?.framleidhandi}"
        },
        "offers": {
          "@type": "Offer",
          "url": "${variant?.images?.[0]?.image?.productList}",
          "priceCurrency": "ISK",
          "price": "${getListingPrice(variant)?.lowestPrice}",
          "availability": "https://schema.org/${
            variant?.isInStockWeb ? 'OutOfStock' : 'InStock'
          }"
        }
      }`,
    }
  }, [variant])

  if (!variant?.product?.name) return null

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={addProductJsonLd}
        key="product-jsonld"
      />
    </Head>
  )
}
