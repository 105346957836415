import React from 'react'

import Link from 'next/link'
import styled, {css} from 'styled-components'
import {palette} from 'styled-tools'
import {media} from 'styled-bootstrap-grid'
import {RemixiconReactIconComponentType} from 'remixicon-react'
import AddLineIcon from 'remixicon-react/AddLineIcon'
import TimeLineIcon from 'remixicon-react/TimeLineIcon'
import SmartphoneLineIcon from 'remixicon-react/SmartphoneLineIcon'
import ShoppingBagLineIcon from 'remixicon-react/ShoppingBagLineIcon'
import FlightTakeoffLineIcon from 'remixicon-react/FlightTakeoffLineIcon'
import NewspaperLineIcon from 'remixicon-react/NewspaperLineIcon'
import FileEditLineIcon from 'remixicon-react/FileEditLineIcon'
import SubtractLineIcon from 'remixicon-react/SubtractLineIcon'
import MailLineIcon from 'remixicon-react/MailLineIcon'

import {AllSpecialHours_allSpecialHoursPeriods as SpecialHours} from '@festi/common/api/datocms/types/AllSpecialHours'
import {FooterFields} from '@festi/common/api/datocms/types/FooterFields'
import {
  Webpage_allSpecialHoursPeriods,
  Webpage_openingHoursDutyfree,
  Webpage_openingHoursGeneral,
} from '@festi/common/api/datocms/types/WebPage'
import {getOpeningHoursToday, isActivePeriod} from '@festi/common/utils/dates'
import settings from '@festi/common/constants/settings'
import {onDutyFree} from '@festi/common/constants/channels'
import {useCommonContext} from '@festi/common/contexts/common'

import {DynamicIcon} from '../common'
import {fluidRange} from '../../utils/styles'
import FooterInfoBulletList from './FooterInfoBulletList'

const Wrapper = styled.div`
  background-color: ${palette('blue')};
  border-bottom: 8px solid ${palette('green')};
  ${fluidRange('padding-top', '60px', '80px')};
  ${fluidRange('padding-bottom', '60px', '80px')};
  z-index: 1;
`

const Container = styled.div`
  width: 100%;
  max-width: 1072px;
  margin: auto;
  padding: 0 16px;
`

const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 30px;

  ${media.md`
    flex-direction: row;
    align-items: unset;
  `}
`

const ContactList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  max-width: 200px;
  margin-bottom: 30px;

  ${media.md`
    gap: 80px;
    max-width: 270px;
    margin-bottom: 0;
  `}
`

const anchorStyle = css`
  display: flex;
  align-items: center;

  .remixicon-icon {
    fill: ${palette('green')};
    margin-right: 24px;
    ${fluidRange('height', '20px', '32px')};
    ${fluidRange('width', '20px', '32px')};
  }

  ${media.md`
    &:last-child {
      margin-bottom: 0;
    }
  `}
`

const ContactLink = styled(Link)`
  ${anchorStyle}
`

const ContactAnchor = styled.a`
  ${anchorStyle}
`

const ContactFocus = styled.div`
  ${anchorStyle}
  cursor: pointer;
`

const ContactAnchorLabel = styled.span`
  color: ${palette('white')};
  font-weight: 500;
  ${fluidRange('font-size', '20px', '32px')};
`

const OpeningHoursWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ExtraOpeningHour = styled.span`
  margin-top: 5px;
  color: ${palette('white')};
  font-size: 0.875rem;
  font-weight: 400;
`

const SocialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 26px;
  width: 100%;
  padding-top: 60px;

  ${media.md`
    flex-direction: row;
    padding-top: 120px;
  `}
`

const SocialHeader = styled.div`
  color: ${palette('white')};
  font-size: 1.125rem;
  font-weight: 500;

  ${media.md`
    font-size: 2.25rem;
  `}
`

const Line = styled.div`
  width: 50%;
  height: 1px;
  margin: auto;
  background-color: ${palette('green')};
`

const Social = styled.div`
  display: flex;
  gap: 16px;
`

interface AnchorProps {
  icon: RemixiconReactIconComponentType
  href?: string
  children: React.ReactNode
  onClick?: () => void
}

const ContactItemAnchor = ({
  href,
  icon: Icon,
  children,
  onClick,
}: AnchorProps) =>
  href ? (
    href.startsWith('/') ? (
      <ContactLink href={href}>
        {!!Icon && <Icon />}
        <ContactAnchorLabel>{children}</ContactAnchorLabel>
      </ContactLink>
    ) : (
      <ContactAnchor href={href} target="_blank">
        {!!Icon && <Icon />}
        <ContactAnchorLabel>{children}</ContactAnchorLabel>
      </ContactAnchor>
    )
  ) : (
    <ContactFocus onClick={onClick}>
      {!!Icon && <Icon />}
      <ContactAnchorLabel>{children}</ContactAnchorLabel>
    </ContactFocus>
  )

interface OpeningHoursProps {
  specialHours?: Webpage_allSpecialHoursPeriods[]
  openingHours?: Webpage_openingHoursGeneral | null
  openingHoursDutyfree?: Webpage_openingHoursDutyfree | null
}

function OpeningHours({
  openingHours,
  specialHours,
}: OpeningHoursProps): JSX.Element {
  let activeSpecialHours: SpecialHours | undefined

  specialHours?.forEach((specialHours) => {
    const {activeFrom, activeTo} = specialHours
    if (isActivePeriod(activeFrom, activeTo)) {
      activeSpecialHours = specialHours
    }
  })

  const openingHoursToday = openingHours && getOpeningHoursToday(openingHours)
  const mainOpeningHour = openingHoursToday?.reduce((prev, current) =>
    prev.occurences > current.occurences ? prev : current,
  )
  const openingHoursLeft = openingHoursToday?.filter(
    (o) => o !== mainOpeningHour,
  )

  if (activeSpecialHours) {
    return (
      <ContactItemAnchor
        icon={TimeLineIcon}
        href={activeSpecialHours.path || '/'}
      >
        {activeSpecialHours.label}
      </ContactItemAnchor>
    )
  }

  return (
    <ContactItemAnchor
      icon={TimeLineIcon}
      href={openingHours?.openingHoursPage?.path || '/opnunartimar'}
    >
      <OpeningHoursWrapper>
        {mainOpeningHour?.openingHours}
        {openingHoursLeft?.map((extraOpeningHour, index) => (
          <ExtraOpeningHour key={index}>
            {extraOpeningHour.storeName}: {extraOpeningHour.openingHours}
          </ExtraOpeningHour>
        ))}
      </OpeningHoursWrapper>
    </ContactItemAnchor>
  )
}

function OpeningHoursDutyfree({
  openingHoursDutyfree,
}: OpeningHoursProps): JSX.Element {
  return (
    <ContactItemAnchor
      icon={TimeLineIcon}
      href={openingHoursDutyfree?.openingHoursPage || '/opnunartimar#leifsstod'}
    >
      <OpeningHoursWrapper>
        {openingHoursDutyfree?.departureStore}
        <ExtraOpeningHour>
          {openingHoursDutyfree?.arrivalStore}
        </ExtraOpeningHour>
      </OpeningHoursWrapper>
    </ContactItemAnchor>
  )
}

interface Props extends Partial<FooterFields> {
  activeNavItem: string | null
  onClickServiceLinks: (type: string | null) => void
}

export default function Footer({
  emailAddress,
  emailAddressLabel,
  emailAddressHref,
  phoneNumber,
  phoneNumberLabel,
  phoneNumberHref,
  storefront,
  dutyfree,
  paper,
  blogg,
  socialMedia,
  activeNavItem,
  footerInfoBullets,
  footerDutyfreeInfoBullets,
  onClickServiceLinks,
}: Props): JSX.Element {
  const {openingHours, specialHours, openingHoursDutyfree, pageNavigation} =
    useCommonContext()

  return (
    <>
      <FooterInfoBulletList
        infoBullets={footerInfoBullets || []}
        dutyFreeInfoBullets={footerDutyfreeInfoBullets || []}
        onDutyFree={onDutyFree}
      />
      <Wrapper>
        <Container>
          <ContactWrapper>
            <ContactList>
              <ContactItemAnchor
                icon={MailLineIcon}
                href={emailAddressHref || `mailto:${emailAddress}`}
              >
                {emailAddressLabel || emailAddress}
              </ContactItemAnchor>

              <ContactItemAnchor
                icon={SmartphoneLineIcon}
                href={phoneNumberHref || `tel:${phoneNumber}`}
              >
                {phoneNumberLabel}
              </ContactItemAnchor>

              {onDutyFree ? (
                <OpeningHoursDutyfree
                  openingHoursDutyfree={openingHoursDutyfree}
                />
              ) : (
                <OpeningHours
                  openingHours={openingHours}
                  specialHours={specialHours}
                />
              )}
            </ContactList>

            <ContactList>
              {onDutyFree ? (
                <ContactItemAnchor
                  icon={ShoppingBagLineIcon}
                  href={storefront?.path || settings.baseUrl}
                >
                  {storefront?.heading || 'ELKO innanlands'}
                </ContactItemAnchor>
              ) : (
                <ContactItemAnchor
                  icon={FlightTakeoffLineIcon}
                  href={dutyfree?.path || settings.dutyfreeUrl}
                >
                  {dutyfree?.heading || 'ELKO flugstöð'}
                </ContactItemAnchor>
              )}

              <ContactItemAnchor
                icon={NewspaperLineIcon}
                href={paper?.path || '/bladid'}
              >
                {paper?.heading || 'Blaðið'}
              </ContactItemAnchor>

              <ContactItemAnchor
                icon={FileEditLineIcon}
                href={blogg?.path || 'https://blogg.elko.is'}
              >
                {blogg?.heading || 'Bloggið'}
              </ContactItemAnchor>
            </ContactList>

            <ContactList>
              {pageNavigation?.allPageNavigations?.map(
                (page) =>
                  !page?.pageLink && (
                    <ContactItemAnchor
                      key={page.id}
                      icon={
                        activeNavItem === page.id
                          ? SubtractLineIcon
                          : AddLineIcon
                      }
                      onClick={() => onClickServiceLinks(page.id)}
                    >
                      {page.heading}
                    </ContactItemAnchor>
                  ),
              )}
            </ContactList>
          </ContactWrapper>

          <SocialWrapper>
            <SocialHeader>Fylgdu okkur</SocialHeader>
            <Line />

            <Social>
              {socialMedia?.map(
                (social) =>
                  !!social?.icon && (
                    <a
                      key={social.id}
                      href={social?.path || '/'}
                      target="_blank"
                    >
                      <DynamicIcon icon={social.icon} size={22} color="green" />
                    </a>
                  ),
              )}
            </Social>
          </SocialWrapper>
        </Container>
      </Wrapper>
    </>
  )
}
