import {styledTheme} from '@festi/common/themes'
import classNames from 'classnames'
import {InputHTMLAttributes, useState, FocusEvent, useRef} from 'react'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'
import SearchLineIcon from 'remixicon-react/SearchLineIcon'
import styled from 'styled-components'

const Root = styled.div`
  position: relative;
  width: 100%;
`
const Input = styled.input`
  width: 100%;
  height: 48px;
  background-color: ${styledTheme.palette.white};
  border: 1px solid ${styledTheme.palette.grey};
  border-radius: 8px;
  padding-inline: 48px;
  padding-block: 14px;
  color: ${styledTheme.palette.blue};
  font-size: 16px;
  font-weight: 500;
  line-height: normal;

  &:focus-visible {
    border-color: ${styledTheme.palette.ui30Solid};
    border-radius: 8px 8px 0 0;
  }
  &:hover {
    border-color: ${styledTheme.palette.ui30Solid};
  }
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${styledTheme.palette.ui40Solid};
  }
  &.small {
    height: 36px;
    padding-inline: 44px;
    padding-block: 8px;
  }
`
const SearchIconContainer = styled.div`
  position: absolute;
  left: 16px;
  top: calc(50% - 10px);

  &:hover + ${Input} {
    border-color: ${styledTheme.palette.ui30Solid};
  }
  &.small {
    left: 12px;
  }
`

const ClearButton = styled.button`
  position: absolute;
  right: 16px;
  top: calc(50% - 10px);
  border: none;
  border-radius: 50%;
  padding: 0;
  cursor: ${styledTheme.cursor.pointer};
  background-color: ${styledTheme.palette.ui10Solid};
  opacity: 1;
  &:hover {
    background-color: ${styledTheme.palette.grey};
  }
  &:hover + ${Input} {
    border-color: ${styledTheme.palette.ui30Solid};
  }
  &.hidden {
    opacity: 0;
  }
  &.small {
    right: 12px;
  }
`

const placeholderText = <>Ég er að leita að &hellip;</>

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  small?: boolean
  onClear?: () => void
}

export default function NewSearchInput({
  small = false,
  onClear,
  ...props
}: InputProps) {
  const ref = useRef<HTMLInputElement>(null)
  const [focused, setFocused] = useState(false)

  const hasValue = !!ref?.current?.value || !!props.value

  function handleFocus(e: FocusEvent<HTMLInputElement>) {
    setFocused(true)
    props.onFocus && props.onFocus(e)
  }

  function handleBlur(e: FocusEvent<HTMLInputElement>) {
    setFocused(false)
    props.onBlur && props.onBlur(e)
  }

  return (
    <Root>
      <SearchIconContainer className={classNames({small})}>
        <SearchLineIcon size={20} color={styledTheme.palette.blue} />
      </SearchIconContainer>
      {onClear && (
        <ClearButton
          onClick={onClear}
          className={classNames({hidden: !focused && !hasValue, small})}
        >
          <CloseLineIcon size={20} color={styledTheme.palette.blue} />
        </ClearButton>
      )}
      <Input
        ref={ref}
        placeholder={props.placeholder ?? placeholderText.props.children}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={classNames({small})}
        {...props}
      />
    </Root>
  )
}
