import React, {useState, useCallback} from 'react'

import TagManager from 'react-gtm-module'
import {toast} from 'react-toastify'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'
import CheckBoxCircleFillIcon from 'remixicon-react/CheckboxCircleFillIcon'
import ErrorWarningFillIcon from 'remixicon-react/ErrorWarningFillIcon'
import MailSendLineIcon from 'remixicon-react/MailSendLineIcon'
import {Col, Row} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import useSWR from 'swr'

import {
  restApi,
  handleRestResponse,
  SubscriptionStatusEnum,
} from '@festi/common/api/rest'
import {Button} from '@festi/common/components/buttons'
import {H2, P} from '@festi/common/components/typography'
import {useAuth} from '@festi/common/contexts'

import {NoticeSticker} from '../common'
import {Sticker} from '../common/NoticeSticker'

const NotificationWrapper = styled.div`
  background-color: ${palette('ui5')};
  padding: 16px;

  ${P} {
    margin-bottom: 16px;
  }

  ${Sticker} {
    margin-bottom: 0;
  }
`

const Header = styled.div`
  display: flex;
`

interface Props {
  sku: string
  status: SubscriptionStatusEnum
}

export default function NotificationSubscription({sku, status}: Props) {
  const {user, setPromptAuth} = useAuth()

  const [subscribed, setSubscribed] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>()

  const isLoggedIn = !!user?.id

  useSWR(
    [sku, isLoggedIn],
    ([sku, userExist]) =>
      userExist &&
      handleRestResponse(restApi.productVariantsSubscriptionRetrieve(sku)),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      onSuccess: (res) => setSubscribed(res.subscribed),
    },
  )

  const subscribe = useCallback(() => {
    if (!subscribed) {
      setIsLoading(true)
      restApi
        .productVariantsSubscriptionCreate(sku)
        .then(() => {
          setSubscribed(true)
          setIsLoading(false)
          TagManager.dataLayer({
            dataLayer: {
              category: 'Out of stock',
              event: 'Subscribe',
              event_label: sku,
            },
            dataLayerName: 'dataLayer',
          })
          toast.info('Skráning tókst')
        })
        .catch((err) => {
          toast.error('Ekki tókst að skrá')
          setIsLoading(false)
          throw err
        })
    }
  }, [subscribed, sku, setSubscribed])

  return (
    <Row>
      <Col>
        {status === SubscriptionStatusEnum.EndOfLife ? (
          <NoticeSticker
            icon={ErrorWarningFillIcon}
            color="blue"
            message="Þessi vara er hætt í sölu og kemur ekki aftur"
          />
        ) : (
          <NotificationWrapper>
            <Header>
              <H2 size={4} withGutter>
                Láttu mig vita
              </H2>
              <MailSendLineIcon />
            </Header>
            {isLoggedIn ? (
              !subscribed ? (
                <>
                  <P withGutter>
                    Fáðu tilkynningu þegar þessi vara er komin aftur á lager.
                  </P>
                  <Button
                    loading={isLoading}
                    onClick={subscribe}
                    disabled={isLoading}
                  >
                    Fá tilkynningu í tölvupósti
                  </Button>
                </>
              ) : (
                <NoticeSticker
                  icon={CheckBoxCircleFillIcon}
                  color="blue"
                  message="Við sendum þér tölvupóst þegar varan kemur aftur á lager í
                vefverslun"
                />
              )
            ) : (
              <>
                <P withGutter>
                  Skráðu þig inn til að fá tilkynningu þegar þessi vara er komin
                  aftur á lager.
                </P>
                <Button
                  withBar
                  icon={ArrowRightSLineIcon}
                  onClick={() => setPromptAuth(true)}
                >
                  Innskráning
                </Button>
              </>
            )}
          </NotificationWrapper>
        )}
      </Col>
    </Row>
  )
}
