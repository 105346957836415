import {palette} from 'styled-tools'
import styled from 'styled-components'
import {toInteger} from 'lodash'

import {styledTheme} from '@festi/common/themes'
import {VariantList, VariantDetail} from '@festi/common/api/rest'
import {H6} from '@festi/common/components/typography'
import {getDiscount} from '@festi/common/utils/checkout'
import {onDutyFree} from '@festi/common/constants/channels'
import {hasFewInStock, isNewProduct} from '@festi/common/utils/products'

const ProductTagsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  gap: 8px;
  width: 100%;
  overflow: hidden;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`

interface TagProps {
  backgroundColor: string
}

const Tags = styled.div<TagProps>`
  position: relative;
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  text-align: center;
  color: ${palette('blue')};
  background-color: ${(p) => p.backgroundColor || palette('ui10Solid')};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -6px;
    bottom: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 30px solid
      ${(p) => p.backgroundColor || palette('ui10Solid')};
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -6px;
    bottom: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 30px solid ${(p) => p.backgroundColor || palette('ui10Solid')};
  }
`

interface Tag {
  title: string
  color: string
}

interface ProductTagsProps {
  variant: VariantList | VariantDetail
}

export default function ProductTags({variant}: ProductTagsProps): JSX.Element {
  const {
    outlet,
    product,
    recommended,
    inventoryStatus,
    isInStockWeb,
    isInStockDutyfree,
  } = variant
  const {online} = product
  const discount = getDiscount(variant)
  const isNew = isNewProduct(online)
  const outOfStock = !(onDutyFree ? isInStockDutyfree : isInStockWeb)
  const fewInStock = hasFewInStock(inventoryStatus)

  const tags: Tag[] = [
    isNew && {title: 'Nýtt', color: 'lightBlue'},
    !!discount && {title: `${toInteger(discount * 100)}%`, color: 'pink'},
    fewInStock && {title: 'Fá eintök', color: 'pink'},
    outOfStock && {title: 'Uppselt', color: 'ui10Solid'},
    recommended && {title: 'Vinsælt', color: 'green'},
    outlet && {title: 'Tækifæri', color: 'black'},
  ]

  return (
    <ProductTagsWrapper>
      {tags?.map(
        (tag, i) =>
          !!tag && (
            <Tags key={i} backgroundColor={styledTheme.palette[tag.color]}>
              <H6>{tag.title}</H6>
            </Tags>
          ),
      )}
    </ProductTagsWrapper>
  )
}
