import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react'

import cn from 'classnames'
import Slider, {Settings} from 'react-slick'
import ReactSlider from 'react-slider'
import {useWindowSize} from 'react-use'
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette, theme} from 'styled-tools'

import {styledTheme, gridTheme} from '@festi/common/themes'

export interface SliderProps {
  children: React.ReactNode
  sm: number // slidesToShow in mobile
  md: number // slidesToShow in tablet
  lg: number // slidesToShow in laptop
  xl: number // slidesToShow in desktop
  darkMode?: boolean
  title?: React.ReactNode
  small?: boolean
}

interface ThumbProps {
  totalSlideSteps?: number
}

export interface SlideInnerProps {
  isHidden?: boolean
  onKeyDown?: React.KeyboardEventHandler<HTMLAnchorElement>
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  onMouseDown?: React.MouseEventHandler<HTMLAnchorElement>
}

const Wrapper = styled.div`
  position: relative;

  .slick-list {
    overflow: visible !important;
    backface-visibility: hidden;
    perspective: 2000px;
  }

  /* Fix for adaptive height in react-slick */
  .slick-slide {
    height: auto;

    & > div {
      height: 100%;
      & > div {
        height: 100%;
      }
    }
  }
  .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
  }
`

const SlideWrapper = styled.div`
  padding-right: 16px;

  &:focus {
    outline: none !important;
  }
`

const SliderHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TitleContentWrapper = styled.div``

const SliderBtnsWrapper = styled.div`
  display: none;
  align-items: center;
  margin-bottom: 24px;
  margin-right: 16px;

  ${media.sm`
    display: flex;

    &.hideOnSm {
      display: none;
    }

  `}

  ${media.md`
    &.hideOnMd {
      display: none;
    }
  `}

  ${media.lg`
    &.hideOnLg {
      display: none;
    }
  `}

  ${media.desktop`
    &.hideOnXl {
      display: none;
    }
  `}
`

const SliderBtn = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background: ${palette('white')};
  border: 1px solid ${palette('ui20Solid')};
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: ${theme('boxShadow.button')};
    opacity: 0;
    transition: opacity 0.15s;
  }

  &.darkMode {
    background: ${palette('blue')};
  }

  &.small {
    width: 40px;
    height: 40px;
  }

  &:first-child {
    margin-right: 5px;
  }

  &:hover,
  &:focus {
    outline: none;

    &:before {
      opacity: 1;
    }
  }
`

const MiniSliderWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;

  ${media.sm`
    display: flex;

    &.hideOnSm {
      visibility: hidden;
    }

  `}

  ${media.md`
    &.hideOnMd {
      visibility: hidden;
    }
  `}

  ${media.lg`
    &.hideOnLg {
      visibility: hidden;
    }
  `}

  ${media.desktop`
    &.hideOnXl {
      visibility: hidden;
    }
  `}
`

export const MiniSlider = styled(ReactSlider)`
  width: 80%;
  max-width: 420px;
  height: 16px;
  cursor: pointer;
`

const MiniSliderTrack = styled.div`
  top: 7px;
  bottom: 7px;
  background: ${palette('ui20Solid')};
  border-radius: 0;
`

const MiniSliderThumb = styled.div<ThumbProps>`
  height: 6px;
  width: ${(p) => 100 / (p.totalSlideSteps || 1)}%;
  margin: 5px 0;
  border: 0;
  border-radius: 0;
  background-color: ${palette('blue')};
  cursor: grab;

  span {
    display: none;
  }

  &:focus {
    outline: none;
  }

  &.darkMode {
    background-color: ${palette('white')};
  }
`

const HiddenDots = styled.div`
  display: none;
`

const SliderWrapper = styled.div`
  &:hover {
    ~ ${MiniSliderWrapper} {
      ${MiniSlider} {
        z-index: -1;
      }
    }
  }
`

let firstClientX: number, clientX: number

const preventTouch = (e: React.TouchEvent) => {
  const minValue = 5 // threshold

  clientX = e.touches[0].clientX - firstClientX

  // Vertical scrolling does not work when you start swiping horizontally.
  if (Math.abs(clientX) > minValue) {
    e.preventDefault()

    return false
  }
}

const touchStart = (e: React.TouchEvent) => {
  firstClientX = e.touches[0].clientX
}

interface MousePosition {
  clientX: number
  clientY: number
}

export default function CustomSlider({
  title,
  children,
  sm,
  md,
  lg,
  xl,
  darkMode = false,
  small = false,
}: SliderProps): JSX.Element {
  const [currSlide, setCurrSlide] = useState<number>(0)
  const sliderRef = useRef<Slider>(null)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const containerRef = useRef<any>(null)
  const [totalSlideSteps, setTotalSlideSteps] = useState<number>(
    (children as React.ReactNode[])?.length || 0,
  )
  const [slidesToShow, setSlidesToShow] = useState<number>()
  const {width} = useWindowSize()
  const dotsRef = useRef<HTMLDivElement>(null)
  const [mousePosition, setMousePosition] = useState<MousePosition>()
  const [swiping, setSwiping] = useState<string>(null)

  const childrenCount = React.Children.count(children)
  const hideOnXl = childrenCount <= xl
  const hideOnLg = childrenCount <= lg
  const hideOnMd = childrenCount <= md
  const hideOnSm = childrenCount <= sm

  const onReInitSlider = useCallback(
    () => setTotalSlideSteps(dotsRef.current?.childElementCount),
    [dotsRef, setTotalSlideSteps],
  )

  const onClickPrev = useCallback(() => {
    if (currSlide > 0) {
      sliderRef?.current?.slickPrev()
      setCurrSlide(currSlide - 1)
    }
  }, [currSlide, sliderRef, setCurrSlide])

  const onClickNext = useCallback(() => {
    if (currSlide < totalSlideSteps - 1) {
      sliderRef?.current?.slickNext()
      setCurrSlide(currSlide + 1)
    }
  }, [totalSlideSteps, currSlide, sliderRef, setCurrSlide])

  const onChangeMiniSlider = useCallback(
    (index: number) => {
      sliderRef.current?.slickGoTo(index)
      setCurrSlide(index)
    },
    [sliderRef, setCurrSlide],
  )

  const onSwipe = useCallback(
    (direction: string) => {
      setSwiping(`${direction}-${currSlide}`)
    },
    [currSlide],
  )

  const onMouseDown = useCallback((e) => {
    setMousePosition({clientX: e.clientX, clientY: e.clientY})
  }, [])

  const onClick = useCallback(
    (e) => {
      e.stopPropagation()
      if (
        mousePosition.clientX !== e.clientX ||
        mousePosition.clientY !== e.clientY
      ) {
        // prevent link click if the element was dragged
        e.preventDefault()
      }
    },
    [mousePosition],
  )

  const settings: Settings = {
    infinite: false,
    arrows: false,
    speed: 400,
    slidesToShow: xl,
    slidesToScroll: 1,
    waitForAnimate: false,
    swipeToSlide: true,
    dots: true,
    onSwipe,
    appendDots: (dots: React.ReactNode[]) => (
      <HiddenDots ref={dotsRef}>{dots}</HiddenDots>
    ),
    onReInit: onReInitSlider,
    useTransform: false,
    touchThreshold: 100,
    accessibility: true,
    responsive: [
      {
        breakpoint: gridTheme?.breakpoints?.lg,
        settings: {
          slidesToShow: lg,
        },
      },
      {
        breakpoint: gridTheme?.breakpoints?.md,
        settings: {
          slidesToShow: md,
        },
      },
      {
        breakpoint: gridTheme?.breakpoints?.sm,
        settings: {
          speed: 200,
          slidesToShow: sm,
        },
      },
    ],
  }

  useEffect(() => {
    const currentRef = containerRef.current

    if (currentRef) {
      currentRef.addEventListener('touchstart', touchStart)
      currentRef.addEventListener('touchmove', preventTouch, {
        passive: false,
      })
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('touchstart', touchStart)
        currentRef.removeEventListener('touchmove', preventTouch, {
          passive: false,
        })
      }
    }
  })

  useLayoutEffect(() => {
    dotsRef.current?.childNodes.forEach((node: HTMLDivElement, index) => {
      const className = node.className

      if (className === 'slick-active') {
        setCurrSlide(index)
      }
    })
  }, [swiping])

  useEffect(() => {
    if (width < gridTheme?.breakpoints?.sm) {
      setSlidesToShow(sm)
    } else if (width < gridTheme?.breakpoints?.md) {
      setSlidesToShow(md)
    } else if (width < gridTheme?.breakpoints?.lg) {
      setSlidesToShow(lg)
    } else {
      setSlidesToShow(xl)
    }
  }, [width, sm, md, lg, xl])

  const focusNextSlide = useCallback(() => {
    if (currSlide < childrenCount - 1) {
      if (currSlide <= childrenCount - slidesToShow) {
        sliderRef?.current?.slickNext()
        setCurrSlide(currSlide + 1)
      } else {
        setCurrSlide(currSlide + 1)
      }
    }
  }, [currSlide, childrenCount, slidesToShow, setCurrSlide])

  const focusPrevSlide = useCallback(() => {
    if (currSlide > 0) {
      if (currSlide <= childrenCount - slidesToShow + 1) {
        sliderRef?.current?.slickPrev()
        setCurrSlide(currSlide - 1)
      } else {
        setCurrSlide(currSlide - 1)
      }
    }
  }, [currSlide, childrenCount, slidesToShow, setCurrSlide])

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === 'Tab' && e.shiftKey) {
        focusPrevSlide()
      } else if (e.key === 'Tab') {
        focusNextSlide()
      } else if (e.key === 'ArrowRight') {
        e.preventDefault()
        onClickNext()
      } else if (e.key === 'ArrowLeft') {
        e.preventDefault()
        onClickPrev()
      }
    },
    [focusPrevSlide, focusNextSlide, onClickNext, onClickPrev],
  )

  return (
    <Wrapper>
      <SliderHeader>
        <TitleContentWrapper>{title}</TitleContentWrapper>
        <SliderBtnsWrapper
          className={cn({hideOnXl, hideOnLg, hideOnMd, hideOnSm})}
        >
          <SliderBtn onClick={onClickPrev} className={cn({small, darkMode})}>
            <ArrowLeftSLineIcon
              size={25}
              color={
                darkMode ? styledTheme.palette.white : styledTheme.palette.blue
              }
              aria-label="previous"
            />
          </SliderBtn>
          <SliderBtn
            onClick={onClickNext}
            className={cn({small, darkMode})}
            aria-label="next"
          >
            <ArrowRightSLineIcon
              size={25}
              color={
                darkMode ? styledTheme.palette.white : styledTheme.palette.blue
              }
            />
          </SliderBtn>
        </SliderBtnsWrapper>
      </SliderHeader>
      <SliderWrapper ref={containerRef}>
        <Slider ref={sliderRef} {...settings}>
          {React.Children.map(
            children,
            (
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              child: React.DetailedReactHTMLElement<any, HTMLElement>,
              index,
            ) => (
              // eslint-disable-next-line react/no-array-index-key
              <SlideWrapper>
                {React.cloneElement(child, {
                  isHidden: currSlide !== index ? true : undefined,
                  onKeyDown: onKeyDown,
                  onClick: onClick,
                  onMouseDown: onMouseDown,
                })}
              </SlideWrapper>
            ),
          )}
        </Slider>
      </SliderWrapper>
      <MiniSliderWrapper
        className={cn({hideOnXl, hideOnLg, hideOnMd, hideOnSm})}
      >
        <MiniSlider
          min={0}
          max={totalSlideSteps - 1}
          value={currSlide}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          renderTrack={(props: any) => <MiniSliderTrack {...props} />}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          renderThumb={(props: any) => (
            <MiniSliderThumb
              {...props}
              totalSlideSteps={totalSlideSteps}
              className={cn({darkMode})}
              aria-label="Slider track"
            />
          )}
          onChange={onChangeMiniSlider}
        />
      </MiniSliderWrapper>
    </Wrapper>
  )
}
