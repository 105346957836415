import React from 'react'

import Link from 'next/link'
import {useRouter} from 'next/router'
import TagManager from 'react-gtm-module'
import {palette} from 'styled-tools'
import styled from 'styled-components'
import {RemixiconReactIconComponentType} from 'remixicon-react'
import EyeLineIcon from 'remixicon-react/EyeLineIcon'
import CheckLineIcon from 'remixicon-react/CheckLineIcon'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'
import RefreshLineIcon from 'remixicon-react/RefreshLineIcon'
import InformationLineIcon from 'remixicon-react/InformationLineIcon'

import {styledTheme} from '@festi/common/themes'
import {onDutyFree} from '@festi/common/constants/channels'
import settings from '@festi/common/constants/settings'
import {Tooltip} from '@festi/common/components/common'

export type StockStatusType =
  | 'in_stock'
  | 'low'
  | 'sold_out'
  | 'showcase'
  | 'no_showcase'

const IconWrapper = styled.div`
  margin: 0 10px;
`

const Status = styled.span`
  color: ${palette('ui70')};
  font-weight: 500;
  font-size: 1rem;
`

const StockStatusWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 6px 0;
`

const Label = styled.div`
  display: flex;
  align-items: center;
  min-width: 115px;
  padding-right: 10px;
  line-height: 24px;
`

const Warehouses = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 6px;
  line-height: 24px;
  font-size: 1rem;
  font-weight: 500;
  font-size: 16px;
`

const Warehouse = styled.div`
  display: flex;
  align-items: center;
  white-space: break-spaces;
  padding: 0 3px;
  border-right: 1px solid ${palette('ui40Solid')};

  &:first-child {
    border-left: 1px solid ${palette('ui40Solid')};
  }
`

const DutyFreeAnchor = styled.a`
  color: inherit;
  padding: 0px 8px;
`

const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
`

const InfoPadding = styled.div`
  padding: 0 10px 0 4px;
`

// TODO: Should content editors be able to change texts?
const statusMap: Record<
  string,
  [string, RemixiconReactIconComponentType, string]
> = {
  in_stock: ['Til á lager', CheckLineIcon, styledTheme.palette.success],
  low: ['Fá eintök', RefreshLineIcon, styledTheme.palette.warning],
  sold_out: ['Uppselt', CloseLineIcon, styledTheme.palette.error],
  showcase: ['Til sýnis', EyeLineIcon, styledTheme.palette.lightBlue],
  no_showcase: ['Til sýnis', CloseLineIcon, styledTheme.palette.error],
}

interface ArrivalProps {
  sku: string
}

export function ArrivalStoreStock({sku}: ArrivalProps) {
  if (!onDutyFree) return null

  return (
    <StockStatusWrapper>
      <Label>
        <Tooltip
          id="arrival-store"
          content={
            <>
              Flestar vörur sem eru til í brottfararverslun eru til í
              komuverslun. Vegna tollalaga er ekki hægt að panta eða taka frá
              vörur í komuverslun.
            </>
          }
          afterShow={() => {
            TagManager.dataLayer({
              dataLayer: {
                event: 'infobubble_viewed',
                event_label: sku,
                category: 'komuverslun',
              },
              dataLayerName: 'dataLayer',
            })
          }}
        >
          <TooltipWrapper>
            <InfoPadding>
              <InformationLineIcon
                size={20}
                color={styledTheme.palette.lightBlue}
              />
            </InfoPadding>
            <Status>Komuverslun</Status>
          </TooltipWrapper>
        </Tooltip>
      </Label>
    </StockStatusWrapper>
  )
}

function DutyFreeLink({label}) {
  const {asPath} = useRouter()
  return (
    <Link href={`${settings.dutyfreeUrl}${asPath}`} passHref legacyBehavior>
      <DutyFreeAnchor>{label}</DutyFreeAnchor>
    </Link>
  )
}

interface StockItemProps {
  warehouse: string
  status: StockStatusType
}

function StockItem({warehouse, status}: StockItemProps) {
  const showDutyFreeLink =
    warehouse === 'Flugstöð' &&
    (status === 'in_stock' || status === 'low') &&
    !onDutyFree

  return (
    <Warehouse
      style={{
        color:
          status === 'sold_out'
            ? styledTheme.palette.ui40Solid
            : styledTheme.palette.blue,
      }}
    >
      {showDutyFreeLink ? <DutyFreeLink label={warehouse} /> : ` ${warehouse} `}
    </Warehouse>
  )
}

interface StatusProps {
  status: StockStatusType
  warehouses?: string[]
}

export default function StockStatus({
  status,
  warehouses,
}: StatusProps): JSX.Element {
  if (!status) {
    return null
  }
  const [statusText, IconComponent, color] = statusMap[status]

  return (
    <StockStatusWrapper>
      <Label>
        <IconWrapper>
          <IconComponent size={20} color={color} />
        </IconWrapper>
        <Status>{statusText}</Status>
      </Label>
      <Warehouses>
        {warehouses?.map((warehouse: string) => (
          <StockItem key={warehouse} warehouse={warehouse} status={status} />
        ))}
      </Warehouses>
    </StockStatusWrapper>
  )
}
